<template>
    <section class="strategic-partnership">
        <Nav
            class="mt-5 mb-4"
            route-name="Partnership"
            parent-page-name="Партнерство"
            current-page-name="Стратегическое партнeрство"
        />

        <div class="strategic-partnership__main-img d-none d-xl-flex">
            <div class="strategic-partnership__blurred-img mt-lg-auto ms-auto d-flex flex-column">
                <h2 class="educational-partnership__title">{{ getStrategicPartnershipPageInfo('title') }}</h2>
                <div class="educational-partnership__text mt-3">
                    {{ getStrategicPartnershipPageInfo('text') }}
                </div>
                <img
                    src="../../assets/page-logo.svg"
                    class="innovative-partnership__logo ms-auto mt-lg-auto d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="title-wrapper d-xl-none my-4 my-lg-5">
            <h2 class="educational-partnership__title educational-partnership__title--dark">
                {{ getStrategicPartnershipPageInfo('title') }}
            </h2>
            <div class="educational-partnership__text educational-partnership__text--dark">
                {{ getStrategicPartnershipPageInfo('text') }}
            </div>
        </div>

        <div class="strategic-partnership__body">
            <div class="d-flex flex-column flex-lg-row">
                <div class="col-12 col-lg-6 ms-sm-2 ms-md-4 ms-lg-0">
                    <h3 class="strategic-partnership__subtitle">{{ getStrategicPartnershipPageInfo('subtitle') }}</h3>
                    <p class="strategic-partnership__subtext mt-3 me-lg-5">
                        {{ getStrategicPartnershipPageInfo('subtext') }}
                    </p>
                </div>

                <div class="col-12 col-lg-6 ms-lg-4">
                    <div class="strategic-card p-4">
                        <div class="d-flex align-items-center">
                            <img
                                src="../../assets/views/strategic_partnership/projects.png"
                                alt=""
                                width="74"
                                height="74"
                                class="d-none d-sm-block"
                            >
                            <div class="strategic-card__title ms-sm-4">{{ getCardInfo(1, 'title') }}</div>
                        </div>

                        <div class="strategic-card__text mt-3">
                            {{ getCardInfo(1, 'description') }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column flex-lg-row mt-4">
                <div class="col-12 col-lg-6">
                    <div class="strategic-card p-4">
                        <div class="d-flex align-items-center">
                            <img
                                src="../../assets/views/strategic_partnership/education.png"
                                alt=""
                                width="74"
                                height="74"
                                class="d-none d-sm-block"
                            >
                            <div class="strategic-card__title ms-sm-4">{{ getCardInfo(2, 'title') }}</div>
                        </div>

                        <div class="strategic-card__text mt-3">
                            {{ getCardInfo(2, 'description') }}
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-6 ms-lg-4 mt-4 mt-lg-0">
                    <div class="strategic-card p-4">
                        <div class="d-flex align-items-center">
                            <img
                                src="../../assets/views/strategic_partnership/science.png"
                                alt=""
                                width="74"
                                height="74"
                                class="d-none d-sm-block"
                            >
                            <div class="strategic-card__title ms-sm-4">{{ getCardInfo(3, 'title') }}</div>
                        </div>

                        <div class="strategic-card__text mt-3">
                            {{ getCardInfo(3, 'description') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="strategic-partnership__opportunities mt-5 d-flex flex-column flex-lg-row align-items-center py-5 py-lg-0">
            <div class="col-12 col-lg-6 d-flex justify-content-center">
                <div class="opportunities__card d-flex justify-content-center">
                    <img
                        src="../../assets/views/strategic_partnership/opportunities.svg"
                        alt=""
                        class="opportunities__img mx-4"
                    >
                </div>
            </div>

            <div class="col-12 col-lg-6 d-flex flex-column align-items-center align-items-lg-start mt-4 mt-lg-0">
<!--                <div class="opportunities__title mx-3">Дополнительные возможности</div>-->
                <div class="opportunities__text mt-3 mx-3">
                    {{ getStrategicPartnershipPageInfo('subtext_2') }}
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Nav from "@/components/Nav/Nav";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";
import { useStore } from "vuex";
import {computed} from "vue";

export default {
    name: "StrategicPartnership",

    components: {
        Nav
    },

    setup() {
        const store = useStore()

        const { isMenuVisible, toggleMenu } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('widgets/getStrategicPartnershipDirectionsWidgets')
        const strategicPartnershipDirectionsWidgets = computed(() => store.getters['widgets/strategicPartnershipDirectionsWidgets'])

        const getCardInfo = (id, key) => {
            if (strategicPartnershipDirectionsWidgets.value) {
                return strategicPartnershipDirectionsWidgets.value.items.find(item => item.id === id)[key];
            }
        }

        store.dispatch('pages/getStrategicPartnershipPage')
        const strategicPartnershipPage = computed(() => store.getters['pages/strategicPartnershipPage'])

        const getStrategicPartnershipPageInfo = (key) => {
            if (strategicPartnershipPage.value) {
                return strategicPartnershipPage.value[key]
            }

            return ''
        }

        return {
            getCardInfo,
            getStrategicPartnershipPageInfo
        }
    }
}
</script>
